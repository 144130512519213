@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/bootflat/2.0.4/css/bootflat.css');
@import url('https://use.fontawesome.com/releases/v5.5.0/css/all.css');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

.header, h1, h2, h3, h4, h5, .panel-heading {
  font-weight: 400;
}

.primary-color {
  color: #7221FF;
}
.sub-color {
  color: #5c5c5c;
}

.badge-ollis {
  background-color: #7221FF;
  border-color: #7221FF;
}

.primary-background-color {
  background: #7221FF !important;
}

code {
  color: #7221FF;
}

html{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  margin-top: 25px;
  font-weight: 300;
}

hr {
  border-top: 1px solid #9372cd;
}

.row {
  padding-bottom: 20px;
}

.no-padding {
  padding: 0px;
}

.bold {font-weight: bold;}

.spec-rows { text-align: left; margin-left: 20px; }

a{color: #7221FF;}
a:hover{color: black;}
a:active{color: black;}
a:visited{color: #7221FF;}

.training-link{color: #535D66}

.top-spacer {
  padding-top: 30px;
}

#bio {
  padding-top: 40px;
}

.medium {
  font-weight: 300 !important;
}

.first-name{
  font-weight: 200;
}
.last-name{
  font-weight: bold;
  margin: -25px 0px;
  font-weight: 300;
}
.job-title{
  font-size: 1.5em;
  font-weight: 300;
  margin-bottom: -5px;
  margin-top: -10px;
}
.job-title-sub{
  font-size: 1.3em;
  font-weight: 200;
}

.career-job-title {
  font-size: 1.1em;
  font-weight: 300;
  padding-bottom: 5px;
}

.header-center-name {
    display: inline-block;
    font-size: 3em;
}

/*!* Small devices (tablets, 768px and up) *!*/
/*@media (min-width: 768px) {*/
/*    .header-center-name {*/
/*        display: block;*/
/*    }*/
/*}*/

.header-center-title {
    text-align: center;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .header-center-title {
        text-align: left;
    }
}

/*.header-center-image {*/
/*    margin: auto;*/
/*}*/

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .header-center-image {
        text-align: right;
    }
}

#skills {
  text-align: center;
}

.skill {
  padding: 2px;
  display: inline-block;
}

.specialization {
  padding-bottom: 20px;
  margin: 0 auto;
}
@media (min-width: 1000px) {
  .specialization {
    margin: 0 20px;
  }
}

.inline {
  display: inline-block;
  vertical-align: middle;
  margin: 7px 0;
}

.timeline dl dd .circ {
  z-index: 0 !important;
}

.color-coral, .color-ruby{
  color: #DA4453 !important;
}

.bg-coral, .bg-ruby{
  background: #DA4453 !important;
  color: white !important;
}

.color-swift{
  color: #F69C37 !important;
}

.bg-swift{
  background: #F69C37 !important;
}

.color-android {
  color: #AECD5D !important;
}

.bg-android {
  background: #AECD5D !important;
}

.color-java {
  color: #5E94AF !important;
}

.bg-java {
  background: #5E94AF !important;
}

.color-kotlin {
  color: #6C54DC !important;
}

.bg-kotlin {
  background: #6C54DC !important;
}

.color-js{
  color: #dccb43 !important;
}

.bg-js{
  background: #EDDB48 !important;
  color: #616161;
}

.color-ml{
  color: #CD460C !important;
}

.bg-ml {
  background: #CD460C !important;
}

.bg-python {
  background: #2A5C91 !important;
}

.panel-heading {
  color: #f3ecff !important;
}


.icons{
  font-size: 5.5em;
  text-align: center;
}
.logos {
  width: 77px;
  height: 77px;
  margin: 0 10px;
  vertical-align: middle;
}

.logo-medium {
  width: 45px;
  height: 45px;
  margin: 5px 3px;
}

.logo-small {
  width: 25px;
  height: 25px;
  margin: 0 2px;
}

.label {
  margin-left: 2px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .icons {
        text-align: left;
    }
}

.about{
  font-size: 1.2em;
  margin: 0px 20px;
  text-align: justify;
  padding-top: 40px;
}

.max
{
  max-width: 1400px;
}

.profile-pic{
  width: 130px;
  height: 130px;
  background-position: -50px -157px;
  background-repeat: no-repeat no-repeat;
  background-size: 240px 444px;
  margin: auto;
}

.contact-wrapper{
  margin: 10px 40px;
  display: inline-block;
}

.footer{
  margin-top: 100px;
}

.events:hover, .repo:hover{
  border: solid 1px #CCC;
  -moz-box-shadow: 1px 1px 5px #999;
  -webkit-box-shadow: 1px 1px 5px #999;
  box-shadow: 1px 1px 5px #999;
  transform: scale(1.05, 1.05);
  transition: all 0.2s ease-in-out;
}
.events, .repo{
  transition: all 0.2s ease-out;
}

.project {
  background-color: #5C1BCC !important;
}
.blog {
  background-color: dodgerblue !important;
}
.video {
  background-color: seagreen !important;
}

.top-padding {
  padding-top: 10px;
}

.footer-copyright {
  color: #f9f2f4;
}

career-logo-container{
  text-align: center;
}

.career-logo {
  /*margin-bottom: 52px;*/
  width: 110px;
  margin: auto;
}

.career-left {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 20px;
}

.career-right {
  display: inline-block;
}

.jobTitle {
  margin-bottom: 4px;
}

.jobDetails {
  font-size: 16px;
  margin-bottom: 0;
}
